import React, { useState } from 'react';
import NextImage from 'next/image';
import { observer } from 'mobx-react-lite';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getWebpImagePath } from '@/utils';

const getContentType = (src) => {
  const parts = src.toLowerCase().split('.');
  const extension = parts[parts.length - 1];
  switch (extension) {
    case 'png':
      return 'image/png';
    default:
      return 'image/jpeg';
  }
};

const Image = ({ src, alt, className, fallback, lazyLoad, unoptimized }) => {
  const [errored, setErrored] = useState(false);

  const onError = () => {
    setErrored(true);
  };

  const imageToShow = errored && fallback ? fallback : src;
  const useWebP = imageToShow.indexOf('https://assets.bidali.com/commerce/brands/other/') === -1;

  if (lazyLoad) {
    return (
      <span className="lazyWrapper">
        <LazyLoadImage alt={alt} src={imageToShow} onError={onError} />
      </span>
    );
  }

  return (
    <picture>
      {useWebP && <source srcSet={getWebpImagePath(imageToShow)} type="image/webp" />}
      <source srcSet={imageToShow} type={getContentType(imageToShow)} />
      <NextImage
        height={150}
        width={250}
        src={imageToShow}
        alt={alt}
        className={className}
        onError={onError}
        unoptimized={unoptimized}
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </picture>
  );
};

export default observer(Image);
