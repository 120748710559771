import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useIntersectionObserver } from 'usehooks-ts'
import { useTranslations } from 'next-intl';

import { breakpoints, fonts } from '@/themes';
import BrandCard from '@/components/brand-card';
import { useStore } from '@/stores/store-provider';

const Title = styled.h2`
  font-family: ${fonts.title};

  html.dark & {
    color: ${({ theme }) => theme.colors.typography.inverted}DD;
  }
`;

const Scrollable = styled.div`
  margin-top: -1.5em;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 1em;
  /* padding: 1.5em 1em; */
  margin-left: -1em;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: calc(100% + 1.5em);
  transition-duration: 250ms;
  transition-property: all;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Padder = styled.div`
  gap: 1em;
  display: flex;
  padding: 1.5em 0 1.5em 1em;
`;

const Empty = styled.div`
  width: 1px;
  height: 2em;
`;

const BrandCardWrapper = styled.div`
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 55%;

  @media screen and (min-width: ${breakpoints.md}px) {
    width: 35%;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: 26%;
  }
`;

const StarredBrands = ({ onBrandClick }) => {
  const store = useStore();
  const t = useTranslations('brandGrid');
  const { isIntersecting: isIntersectingFirstItem, ref: firstItem } = useIntersectionObserver({
    threshold: 0.5,
  });

  const { isIntersecting: isIntersectingLastItem, ref: lastItem } = useIntersectionObserver({
    threshold: 0.5,
  });

  const getMaskImage = () => {
    if (isIntersectingFirstItem) {
      return `linear-gradient(to right,
        white,
        white calc(100% - 3em),
        transparent
      )`;
    }

    if (isIntersectingLastItem) {
      return `linear-gradient(to right,
        transparent,
        white 3em,
        white
      )`;
    }

    return `linear-gradient(to right,
      transparent,
      white 3em,
      white calc(100% - 3em),
      transparent
    )`;
  };

  const handleBrandClick = brand => () => {
    if (brand && typeof onBrandClick === 'function') {
      onBrandClick(brand);
    }
  };

  if (store.favorites.length === 0) {
    return null;
  }

  return (
    <div>
      <Title>{t('favorites')}</Title>
      <Scrollable style={{ maskImage: getMaskImage() }}>
        <Padder>
          <Empty ref={firstItem} style={{ marginRight: '-1em' }}>&nbsp;</Empty>
          {store.favorites.map(brand => (
            <BrandCardWrapper key={brand.id}>
              <BrandCard brand={brand} onClick={typeof onBrandClick === 'function' ? handleBrandClick(brand) : undefined} />
            </BrandCardWrapper>
          ))}
          <Empty ref={lastItem} style={{ marginLeft: '-0.75em' }}>&nbsp;</Empty>
        </Padder>
      </Scrollable>
    </div>
  );
};

export default observer(StarredBrands);
