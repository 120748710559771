import dynamic from 'next/dynamic';
import styled from 'styled-components';
import Link from 'next/link';

import { media } from '@/themes';
import { getFallbackImage } from '@/utils';
import Image from '@/components/image';

const Card = styled.div`
  background-color: ${props => props.$backgroundColor || props.theme.background.card};
  border-radius: .5em;
  box-shadow: ${props => props.theme.shadows.primary};
  cursor: pointer;
  padding: 1em;
  pointer-events: none;
  position: relative;
  transition-duration: 250ms;
  transition-property: box-shadow, transform;
  z-index: 1;
`;

const BrandLogo = dynamic(() => Promise.resolve((styled(Image).attrs({ unoptimized: true })`
  height: 100%;
  max-height: 150px;
  max-width: 250px;
  width: 100%;
`)), { ssr: false });

const BrandName = styled.p`
  color: ${({ theme }) => theme.colors.typography.primary};
  cursor: pointer;
  font-size: .85em;
  margin: .5em 0 0;
  text-align: center;
  text-decoration: none;
  transition-property: opacity, transform;
  transition-duration: 500ms;
  will-change: opacity, transform;

  &:hover {
    color: #4554e8;
  }

  [class*="dapp__Wrapper"] & {
    display: none;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  max-width: 100%;
  z-index: 1;

  &:hover {
    z-index: 2;

    ${BrandName} {
      opacity: 0;
      transform: translateY(-.5em);

      ${media.sm`
        opacity: 1;
        transform: none;
      `};
    }

    ${Card} {
      box-shadow: ${props => props.theme.shadows.hover};
      transform: translateY(.125em) scale(1.05);

      ${media.sm`
        box-shadow: ${props => props.theme.shadows.primary};
        transform: none;
      `};
    }
  }
`;

const urlForBrand = brand => {
  const countrySlug = brand.countrySlug || brand.country.slug;
  return `/brands/${countrySlug}/${brand.slug}`;
};

const BrandCard = ({ brand, hideName = false, className, style, onClick }) => {
  if (!brand) {
    return null;
  }

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick(brand);
    }
  };

  if (typeof onClick === 'function') {
    return (
      <CardWrapper onClick={handleClick} style={style} className={className}>
        <Card $backgroundColor={brand.backgroundColor}>
          <BrandLogo src={brand.imageUrl} width={250} height={150} alt={brand.name} fallback={getFallbackImage(brand.type)} />
        </Card>
        {!hideName && <BrandName>{brand.name}</BrandName>}
      </CardWrapper>
    );
  }

  return (
    <Link
      key={brand.id}
      href={urlForBrand(brand)}
      prefetch={false}
      title={`Buy ${brand.name} vouchers / gift cards with Bitcoin, Litecoin, Ethereum, USDT, USDC and more.`}
      style={{ display: 'block', ...style }}
      className={className}
      onClick={onClick}
    >
      <CardWrapper>
        <Card $backgroundColor={brand.backgroundColor}>
          <BrandLogo src={brand.imageUrl} width={250} height={150} alt={brand.name} fallback={getFallbackImage(brand.type)} />
        </Card>
        {!hideName && <BrandName>{brand.name}</BrandName>}
      </CardWrapper>
    </Link>
  );
};

export default BrandCard;
