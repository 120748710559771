import styled from 'styled-components';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { darkMode } from '@/themes';
import config from '@/config';
import useDappHandlers from '@/hooks/use-dapp-handlers';

const Container = styled.div`
  background: rgba(255, 255, 255, .75);
  border-radius: 1rem;
  bottom: 1rem;
  box-shadow: ${({ theme }) => theme.shadows.hover};
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 0;
  position: sticky;
  z-index: 2;

  html.dark & {
    background: ${({ theme }) => theme.colors.typography.secondary}AA;
  }

  @supports (backdrop-filter: blur(.5em)) {
    background: rgba(255, 255, 255, .5);
    backdrop-filter: blur(.5em);

    html.dark & {
      background: ${({ theme }) => theme.colors.typography.secondary}AA;
    }
  }
`;

const Logo = styled(Image)`
  margin-left: .5em;

  > span {
    display: block;
  }

  html.dark & {
    filter: brightness(2.7) saturate(0);
  }
`;

const Link = styled.a``;

const PoweredBy = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  font-size: .75em;
  mix-blend-mode: hard-light;
  padding: 1rem;

  html.dark & {
    color: rgba(255, 255, 255, .5);
  }
`;

const Links = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, .025);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: rgba(255, 255, 255, .5);
  display: flex;
  font-size: .75em;
  justify-content: space-around;
  padding: 1rem;

  html.dark & {
      background: rgba(0, 0, 0, .05);
    }

  a {
    color: ${({ theme }) => theme.colors.primary};

    html.dark & {
      color: ${({ theme }) => theme.colors.typography.inverted};
    }
  }
`;

const Footer = () => {
  const dappHandlers = useDappHandlers();
  const router = useRouter();

  const handleUrlClick = url => event => {
    event.preventDefault();

    if (typeof dappHandlers.openUrl === 'function') {
      return dappHandlers.openUrl(url);
    }

    if (url.startsWith('http') && typeof window !== 'undefined') {
      return window.open(url, '_blank');
    }

    return router.push(url);
  };

  return (
    <Container>
      <Links>
        <Link onClick={handleUrlClick(config.supportUrl)} href="#">Support</Link>
        <Link onClick={handleUrlClick(config.termsUrl)} href="#">Terms & Conditions</Link>
        <Link onClick={handleUrlClick(config.privacyPolicyUrl)} href="#">Privacy Policy</Link>
      </Links>
      <PoweredBy>
        Powered by
        <Logo
          alt="Bidali"
          height={16}
          priority
          src="https://assets.bidali.com/company/bidali/logo.png"
          unoptimized
          width={64} />
      </PoweredBy>
    </Container>
  );
};

export default Footer;
